.border-gradient {
    display: inline-block;
    position: relative;
    z-index: 0;

    &:before,
    &:after {
        width: 100%;
        content: "";
        position: absolute;
        inset: 0;
        padding: 1px;
    }
    &:before {
        z-index: -2;
        background: linear-gradient(to right, theme("colors.purple-rgba"), theme("colors.white"));
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        opacity: 0;
        transition: opacity 0s theme('transitionDuration.fast') ease-in-out;
    }
    &:after {
        z-index: -1;
        background: theme("colors.white");
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        transition: opacity theme('transitionDuration.fast') ease-in-out;
    }

    &.opened:after {
        opacity: 0;
    }
    &.opened:before {
        opacity: 1;
        transition: theme('transitionDuration.medium');
    }
    @media (hover: hover) {
        &:hover:after {
            opacity: 0;
        }
        &:hover:before {
            opacity: 1;
            transition: theme('transitionDuration.medium');
        }
    }
}
