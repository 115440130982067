.st0 {
    fill: #ffffff;
}
.st1 {
    fill: #5f6368;
}
.st2 {
    fill: #4284f4;
}
.st3 {
    fill: #34a853;
}
.st4 {
    fill: #fbbc04;
}
.st5 {
    fill: #ea4335;
}
