.summary-box {

    /* PLUS SIGN */
    .plus-sign {
        display: block;
        position: relative;
        --width: 24px;
        --thickness: 2px;
        width: var(--width);
        height: var(--width);
    }
    
    .plus-sign span:nth-child(1) {
        display: block;
        position: absolute;
        width: 100%;
        height: var(--thickness);
        background: theme('colors.white');
        top: calc(50% - (var(--thickness) / 2));
        transition: all 0.5s ease;
    }
    
    .plus-sign span:nth-child(2) {
        display: block;
        position: absolute;
        width: var(--thickness);
        height: 100%;
        background: theme('colors.white');
        top: 0;
        left: calc(50% - (var(--thickness) / 2));
        transition: all 0.5s ease;
    }
    
    &.opened {
        .plus-sign span:nth-child(2) {
            opacity: 0;
        }
    }
}