@font-face {
    font-family: "HordeDarkMatter";
    src: url("assets/fonts/HordeDarkMatter/HordeDarkMatter-Bold.woff2") format("woff2"),
        url("assets/fonts/HordeDarkMatter/HordeDarkMatter-Bold.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: block;
}
@font-face {
    font-family: "Alaska";
    src: url("assets/fonts/Alaska/Alaska-Light.woff2") format("woff2"),
        url("assets/fonts/Alaska/Alaska-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Alaska";
    src: url("assets/fonts/Alaska/Alaska-Regular.woff2") format("woff2"),
        url("assets/fonts/Alaska/Alaska-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Alaska";
    src: url("assets/fonts/Alaska/Alaska-Medium.woff2") format("woff2"),
        url("assets/fonts/Alaska/Alaska-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Alaska";
    src: url("assets/fonts/Alaska/Alaska-Bold.woff2") format("woff2"),
        url("assets/fonts/Alaska/Alaska-Bold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

*,
*:before,
*:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* values for the styles, weights and sizes are handled by tailwind */
:root {
    // Ensure root font size is 16px (which is the norm)
    font-size: 16px;
}
// Default font style
body {
    font-family: theme("fontFamily.body");
    font-weight: theme("fontWeight.regular");
}

::-moz-selection {
    /* Code for Firefox */
    color: theme('colors.black');
    background: theme('colors.white');
    -webkit-text-fill-color: theme('colors.black');
}
::selection {
    color: theme('colors.black');
    background: theme('colors.white');
    -webkit-text-fill-color: theme('colors.black');
}
.inverted-colors {
    ::-moz-selection {
        /* Code for Firefox */
        color: theme('colors.white');
        background: theme('colors.black');
        -webkit-text-fill-color: theme('colors.white');
    }
    ::selection {
        color: theme('colors.white');
        background: theme('colors.black');
        -webkit-text-fill-color: theme('colors.white');
    }
}
