@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

#App {
  min-height: 100vh;
}

button, a {
  -webkit-tap-highlight-color: transparent;
}
